<template>
    <div class="box w-24 flex items-center justify-center flex-none">
        <div class="text-center" v-if="row.hasOwnProperty('OnGoing')">
            <!-- <div class="text-xl text-font-gray mb-8">Total</div> -->
            <div class="text-2xl">{{ row.OnGoing.total.oportunity | numberFormat() }}</div>
            <div class="text-font-gray">{{ row.OnGoing.total.sales | reduceBigNumbers(2) }}€</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "BallOnGoingTotal",
        props: ['row']
    }
</script>

<style scoped>

</style>